import React, { useRef, useEffect } from "react";

import bt from "./assets/images/bt1-left-tilt.png";

const HomeImages2 = (props) => {
  const observer = useRef(
    new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          let element = document.getElementById("backtestImg");
          if (entry.isIntersecting) {
            element.classList.add("right-slide-full");
            element.classList.remove("d-none");
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.85,
      }
    )
  );
  useEffect(() => {
    observer.current.observe(document.getElementById("backtest"));
  }, []);

  return (
    <img id="backtestImg" className="rounded w-100 d-none pt-3 pt-md-0" src={bt} alt="EasyCryptoBot Crypto Trading Bot Backtest" />
  );
};

export default HomeImages2;
